/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import image1 from "../../assets/Room/2+1.png";

const RoomLayoutTwo = ({ t }) => {
    return (
        <>
            <section className="uk-section">
                <div className="uk-container uk-container-large">
                    <div className="" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div className="uk-width-2-3@m">
                            <div className="uk-child-width-1-2@m uk-grid-small uk-text-center" data-uk-grid>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Giriş:</span> 5,25 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Hol:</span> 2,22 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Kiler:</span> 3,09 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Salon + Mutfak:</span> 22,62 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">E. Yatak Odası:</span> 14,30 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Çocuk Odası:</span> 10,38 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Banyo:</span> 5,29 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Balkon:</span> 7,88 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Net Alan:</span> 71,03 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Brüt Alan:</span> 93,75 m2
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-hover">
                                        <div className="uk-card-body">
                                            <span className="uk-text-bold">Genel Brüt Alan:</span> 102,20 m2
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-3@m" data-uk-lightbox>
                            <a className="uk-inline-clip uk-transition-toggle" tabindex="0" href={image1}>
                                <img src={image1} alt="" data-uk-img />
                                <div className="uk-transition-fade uk-position-cover uk-overlay uk-overlay-default uk-flex uk-flex-center uk-flex-middle">
                                    <div className="uk-text-emphasis" data-uk-icon="icon:plus; ratio:3;"/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(RoomLayoutTwo);