/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import image1 from "../../assets/Gallery/lightbox/1.jpg";
import image2 from "../../assets/Gallery/lightbox/2.jpg";
import image3 from "../../assets/Gallery/lightbox/3.jpg";
import image4 from "../../assets/Gallery/lightbox/4.jpg";
import image5 from "../../assets/Gallery/lightbox/5.jpg";
import image6 from "../../assets/Gallery/lightbox/6.jpg";
import image7 from "../../assets/Gallery/lightbox/7.jpg";
import image8 from "../../assets/Gallery/lightbox/8.jpg";
import image9 from "../../assets/Gallery/lightbox/9.jpg";
import image10 from "../../assets/Gallery/lightbox/10.jpg";
import image11 from "../../assets/Gallery/lightbox/11.jpg";
import image12 from "../../assets/Gallery/lightbox/12.jpg";
import image13 from "../../assets/Gallery/lightbox/13.jpg";
import image14 from "../../assets/Gallery/lightbox/14.jpg";
import image15 from "../../assets/Gallery/lightbox/15.jpg";
import image16 from "../../assets/Gallery/lightbox/16.jpg";
import image17 from "../../assets/Gallery/lightbox/17.jpg";
import image18 from "../../assets/Gallery/lightbox/18.jpg";
import image19 from "../../assets/Gallery/lightbox/19.jpg";
import image20 from "../../assets/Gallery/lightbox/20.jpg";
import image21 from "../../assets/Gallery/lightbox/21.jpg";
import image22 from "../../assets/Gallery/lightbox/22.jpg";
import image23 from "../../assets/Gallery/lightbox/23.jpg";
import image24 from "../../assets/Gallery/lightbox/24.jpg";
import image25 from "../../assets/Gallery/lightbox/25.jpg";
import image26 from "../../assets/Gallery/lightbox/26.jpg";
import image27 from "../../assets/Gallery/lightbox/27.jpg";
import image28 from "../../assets/Gallery/lightbox/28.jpg";
import image29 from "../../assets/Gallery/lightbox/29.jpg";
import image30 from "../../assets/Gallery/lightbox/30.jpg";
import image31 from "../../assets/Gallery/lightbox/31.jpg";
import image32 from "../../assets/Gallery/lightbox/32.jpg";
import image33 from "../../assets/Gallery/lightbox/33.jpg";
import image34 from "../../assets/Gallery/lightbox/34.jpeg";
import image35 from "../../assets/Gallery/lightbox/35.jpeg";
import image36 from "../../assets/Gallery/lightbox/36.jpeg";
import image37 from "../../assets/Gallery/lightbox/37.jpeg";
import image38 from "../../assets/Gallery/lightbox/38.jpeg";
import image39 from "../../assets/Gallery/lightbox/39.jpeg";
import image40 from "../../assets/Gallery/lightbox/40.jpeg";
import image41 from "../../assets/Gallery/lightbox/41.jpeg";
import thumbnailImage1 from "../../assets/Gallery/1.jpg";
import thumbnailImage2 from "../../assets/Gallery/2.jpg";
import thumbnailImage3 from "../../assets/Gallery/3.jpg";
import thumbnailImage4 from "../../assets/Gallery/4.jpg";
import thumbnailImage5 from "../../assets/Gallery/5.jpg";
import thumbnailImage6 from "../../assets/Gallery/6.jpg";
import thumbnailImage7 from "../../assets/Gallery/7.jpg";
import thumbnailImage8 from "../../assets/Gallery/8.jpg";
import thumbnailImage9 from "../../assets/Gallery/9.jpg";
import thumbnailImage10 from "../../assets/Gallery/10.jpg";
import thumbnailImage11 from "../../assets/Gallery/11.jpg";
import thumbnailImage12 from "../../assets/Gallery/12.jpg";
import thumbnailImage13 from "../../assets/Gallery/13.jpg";
import thumbnailImage14 from "../../assets/Gallery/14.jpg";
import thumbnailImage15 from "../../assets/Gallery/15.jpg";
import thumbnailImage16 from "../../assets/Gallery/16.jpg";
import thumbnailImage17 from "../../assets/Gallery/17.jpg";
import thumbnailImage18 from "../../assets/Gallery/18.jpg";
import thumbnailImage19 from "../../assets/Gallery/19.jpg";
import thumbnailImage20 from "../../assets/Gallery/20.jpg";
import thumbnailImage21 from "../../assets/Gallery/21.jpg";
import thumbnailImage22 from "../../assets/Gallery/22.jpg";
import thumbnailImage23 from "../../assets/Gallery/23.jpg";
import thumbnailImage24 from "../../assets/Gallery/24.jpg";
import thumbnailImage25 from "../../assets/Gallery/25.jpg";
import thumbnailImage26 from "../../assets/Gallery/26.jpg";
import thumbnailImage27 from "../../assets/Gallery/27.jpg";
import thumbnailImage28 from "../../assets/Gallery/28.jpg";
import thumbnailImage29 from "../../assets/Gallery/29.jpg";
import thumbnailImage30 from "../../assets/Gallery/30.jpg";
import thumbnailImage31 from "../../assets/Gallery/31.jpg";
import thumbnailImage32 from "../../assets/Gallery/32.jpg";
import thumbnailImage33 from "../../assets/Gallery/33.jpg";
import thumbnailImage34 from "../../assets/Gallery/34.jpg";
import thumbnailImage35 from "../../assets/Gallery/35.jpg";
import thumbnailImage36 from "../../assets/Gallery/36.jpg";
import thumbnailImage37 from "../../assets/Gallery/37.jpg";
import thumbnailImage38 from "../../assets/Gallery/38.jpg";
import thumbnailImage39 from "../../assets/Gallery/39.jpg";
import thumbnailImage40 from "../../assets/Gallery/40.jpg";
import thumbnailImage41 from "../../assets/Gallery/41.jpg";

const GalleryContent = ({ t }) => {
    return (
        <section className="uk-section">
            <div className="uk-container">
                <div className="uk-child-width-1-2 uk-child-width-1-4@m" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div>
                        <div data-uk-lightbox>
                            <a href={image1} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage1} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image2} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage2} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image3} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage3} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image4} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage4} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image5} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage5} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image6} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage6} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image7} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage7} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image8} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage8} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image9} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage9} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image10} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage10} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image11} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage11} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image12} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage12} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image13} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage13} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image14} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage14} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image15} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage15} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image16} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage16} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image17} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage17} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image18} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage18} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image19} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage19} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image20} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage20} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image21} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage21} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image22} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage22} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image23} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage23} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image24} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage24} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image25} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage25} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image26} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage26} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image27} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage27} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image28} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage28} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image29} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage29} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image30} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage30} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image31} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage31} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image32} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage32} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image33} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage33} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image34} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage34} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image35} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage35} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image36} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage36} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image37} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage37} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image38} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage38} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image39} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage39} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image40} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage40} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div data-uk-lightbox>
                            <a href={image41} className="uk-width-1-1 uk-height-1-1">
                                <img data-src={thumbnailImage41} alt="" data-uk-img className="uk-width-1-1 uk-height-1-1"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(GalleryContent);