/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import ProjectDetailAccordion from "../components/ProjectDetail/ProjectDetailAccordion";
import ProjectDetailGallery from "../components/ProjectDetail/ProjectDetailGallery";
import ProjectDetailIntro from "../components/ProjectDetail/ProjectDetailIntro";
import ProjectDetailSiteFeatures from "../components/ProjectDetail/ProjectDetailSiteFeatures";

const ProjectDetailScreen = ({ t }) => {
    return (
        <>
            <ProjectDetailGallery/>
            <ProjectDetailIntro/>
            <ProjectDetailAccordion/>
            <ProjectDetailSiteFeatures/>
        </>
    );
};

export default withTranslation()(ProjectDetailScreen);