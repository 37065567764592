/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const Video = ({ t }) => {
    return (
        <>
            <section className="uk-section">
                <div className="uk-container">
                    <iframe className="uk-width-1-1 video-height" src="https://www.youtube.com/embed/poJ9s8yNW7E?autoplay=1&mute=1" title="ASSEMCORP ÇORLU 10 06 2022" frameborder="0" allow="autoplay; fullscreen"></iframe>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(Video);