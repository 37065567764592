import { Routes,Route, useLocation} from 'react-router-dom';
import Navbar from './components/Navbar';
import HomeScreen from './pages/HomeScreen';
import ProjectDetailScreen from './pages/ProjectDetailScreen';
import RoomScreen from './pages/RoomScreen';
import Footer from "./components/Footer";
import GalleryScreen from './pages/GalleryScreen';
import ContactScreen from './pages/ContactScreen';
import { useEffect, useState } from 'react';
import Sidebar from './components/Sidebar/Sidebar';

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  const [isopen, setisopen] = useState(false)
  const toggle = () => {
    setisopen(!isopen)
  }
  return (
    <>
        <Navbar toggle={toggle}/>
        <Sidebar isopen={isopen} toggle={toggle} />
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/project-detail" element={<ProjectDetailScreen />} />
            <Route path="/room" element={<RoomScreen />} />
            <Route path="/gallery" element={<GalleryScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
        </Routes>
      <Footer/>
    </>
  );
}

export default App;
