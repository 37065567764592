/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

export default function ContactDescripton() {
    return (
        <section>
            <div className="uk-container uk-container-xlarge">
                <section className="uk-section uk-padding-remove-top">
                    <div className="uk-container uk-container-xlarge">
                        <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center uk-text-center grid-section-background" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                            <div className="uk-card uk-card-default uk-card-hover uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div uk-icon="icon: location; ratio: 2" className="uk-text-emphasis"/>
                                <div className="uk-h4 uk-margin-small uk-text-bold">Adres</div>
                                <div className="uk-h5 uk-margin-small">
                                    <a href="https://goo.gl/maps/tSbqG1FdWrAreqKp7" target="_blank" className='uk-link-reset'>Tekirdağ Yolu Üzeri 1.km Çorlu Belediyesi Kültür Merkezi ve Nikah Salonu Arkası</a>
                                </div>
                            </div>
                            <div className="uk-card uk-card-default uk-card-hover uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div uk-icon="icon: mail; ratio: 2" className="uk-text-emphasis"/>
                                <div className="uk-h4 uk-margin-small uk-text-bold">E-Mail</div>
                                <div className="uk-h5 uk-margin-small"><a className="uk-link-reset" href="mailto:construction@assemcorp.com">construction@assemcorp.com</a></div>
                            </div>
                            <div className="uk-card uk-card-default uk-card-hover uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div uk-icon="icon: phone; ratio: 2" className="uk-text-emphasis"/>
                                <div className="uk-h4 uk-margin-small uk-text-bold">Telefon</div>
                                <div className="uk-h5 uk-margin-small"><a href="tel:+908506110882" className="uk-link-reset">+90 850 611 0 882</a></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}
