/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const RoomIntro = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-padding-remove-bottom">
                <div className="uk-container uk-container-large">
                    <div className="uk-text-bold fs-42" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <span className="uk-text-emphasis">2+1 </span><span>Daire</span>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(RoomIntro);