/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const ProjectDetailIntro = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-padding-remove-bottom">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center">
                        <div data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                            <h1 className="uk-h2">Muhteşem Lokasyonuyla yüksek yatırım getirisi vadediyor </h1>
                            <p>Çorlu - Tekirdağ yoluna 1. km mesafede bulunan arsa üzerinde yer alan ve ana artere yakınlık açısından 2. arsa konumundaki alan üzerine inşa edilen Çorlu Ulus Residence projesi, sadece bulunduğu lokasyonun değil, Türkiye’nin marka yaşam alanları arasında yer alma hedefiyle yeni sahiplerini bekliyor. Çorlu’nun cazibe merkezi Ulus Residence ile hayatın ve şehrin merkezindesiniz... Hemen yanı başında Çorlu TED Koleji, Çorlu Belediyesi Evlendirme Dairesi Nikah Salonu, Çorlu Belediyesi Kültür Merkezi ve Çorlu Şehir Stadyumu yer alan Ulus Residence’ın eğitim, kültür, sanat ve spor kurumlarıyla iç içe olan bu konumu, bir yandan merkezi lokasyonunu benzersiz kılarken bir yandan da sakinlerine kaliteli bir yaşam ve dolu dolu sosyalleşme fırsatı sunuyor.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-section uk-section-small">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center">
                        <div data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                            <h1 className="uk-h2">Lüks yaşam hiç bu kadar ulaşılabilir olmamıştı...</h1>
                            <p>Çorlu Ulus Residence’da olmak, hem ev sahiplerine hem de kiracılara lüks bir konfor imkanı sağlıyor. Projenin yer aldığı lokasyonda artan konut ihtiyacına karşın kısıtlı olan konut arzından dolayı dairelere olan talep her geçen gün artarken yatırımcılar da kısa sürede yüksek kira getirisi, yüksek pirim ve amortisman imkanına sahip oluyor. Siz de ulaşılabilir lükse çocuklarınızın kumbara hesabındaki birikimleriyle sahip olabilir, karlı bir yatırım yaparak yarınlarını güvence altına alabilirsiniz.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectDetailIntro);