/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import image1 from "../../assets/ProjectDetail/lightbox/1.jpeg";
import image2 from "../../assets/ProjectDetail/lightbox/2.jpeg";
import image3 from "../../assets/ProjectDetail/lightbox/3.jpeg";
import image4 from "../../assets/ProjectDetail/lightbox/4.jpeg";
import image5 from "../../assets/ProjectDetail/lightbox/5.jpeg";
import image6 from "../../assets/ProjectDetail/lightbox/6.jpeg";
import image7 from "../../assets/ProjectDetail/lightbox/7.jpeg";
import image8 from "../../assets/ProjectDetail/lightbox/8.jpeg";
import image9 from "../../assets/ProjectDetail/lightbox/9.jpeg";
import image10 from "../../assets/ProjectDetail/lightbox/10.jpeg";
import image11 from "../../assets/ProjectDetail/lightbox/11.jpeg";
import image12 from "../../assets/ProjectDetail/lightbox/12.jpeg";
import thumbnailImage1 from "../../assets/ProjectDetail/1.jpeg";
import thumbnailImage2 from "../../assets/ProjectDetail/2.jpeg";
import thumbnailImage3 from "../../assets/ProjectDetail/3.jpeg";
import thumbnailImage4 from "../../assets/ProjectDetail/4.jpeg";
import thumbnailImage5 from "../../assets/ProjectDetail/5.jpeg";
import thumbnailImage6 from "../../assets/ProjectDetail/6.jpeg";
import thumbnailImage7 from "../../assets/ProjectDetail/7.jpeg";
import thumbnailImage8 from "../../assets/ProjectDetail/8.jpeg";
import thumbnailImage9 from "../../assets/ProjectDetail/9.jpeg";
import thumbnailImage10 from "../../assets/ProjectDetail/10.jpeg";
import thumbnailImage11 from "../../assets/ProjectDetail/11.jpeg";
import thumbnailImage12 from "../../assets/ProjectDetail/12.jpeg";

const ProjectDetailGallery = ({ t }) => {
    return (
        <section>
            <div>
            <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" data-uk-slider="autoplay:true; autoplay-interval:4000; sets:true;">
                <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-grid-small" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image1} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage1} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image2} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage2} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image3} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage3} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image4} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage4} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image5} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage5} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image6} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage6} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image7} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage7} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image8} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage8} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image9} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage9} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image10} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage10} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image11} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage11} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="uk-panel uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                            <a href={image12} className="uk-width-1-1 uk-height-1-1">
                                <img src={thumbnailImage12} alt="" data-uk-img="loading:lazyload;" className="uk-width-1-1 uk-height-1-1" />
                            </a>
                        </div>
                    </li>
                </ul>
                <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ProjectDetailGallery);