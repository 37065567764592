/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import HomeFirstContainer from "../components/Home/HomeFirstContainer";
import HomeSecondContainer from "../components/Home/HomeSecondContainer";
import HomeThirdContainer from "../components/Home/HomeThirdContainer";
import HomeSlideshow from "../components/Home/HomeSlideshow";
import HomeSecondContainerMobile from "../components/Home/HomeSecondContainerMobile";
import HomeThirdContainerMobile from "../components/Home/HomeThirdContainerMobile";
import Video from "../components/Common/Video";

const HomeScreen = ({ t }) => {
    return (
        <>
            <HomeSlideshow/>
            <Video/>
            <HomeFirstContainer/>
            <HomeSecondContainer/>
            <HomeSecondContainerMobile/>
            <HomeThirdContainer/>
            <HomeThirdContainerMobile/>
        </>
    );
};

export default withTranslation()(HomeScreen);