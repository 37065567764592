/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const ProjectDetailAccordion = ({ t }) => {
    return (
        <>
            <section className="uk-section">
                <div className="uk-section uk-padding-remove-top">
                    <div className="uk-container uk-flex uk-flex-center">
                        <div className="uk-h2 uk-text-bold" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">Ev Özellikleri</div>
                    </div>
                </div>
                <div className="uk-container">
                    <ul data-uk-accordion data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <li className="uk-open">
                            <a className="uk-accordion-title" href="#">Klima</a>
                            <div className="uk-accordion-content">
                                <p>Klima konfordur. Ulus Residence’da her evde bulunan klima ile yazın keyfini evinizde çıkaracak, tatil konforunu evinizde yaşayacaksınız. Ulus Residence’da hem yatırımcılara, hem kiracılara, hem de hitap edeceği kullanıcıya göre tüm detaylar tek tek özenle ele alınarak tasarlandı. </p>
                            </div>
                        </li>
                        <li>
                            <a className="uk-accordion-title" href="#">Kiler</a>
                            <div className="uk-accordion-content">
                                <p>Çorlu Ulus Residence’ı benzer marka yaşam projelerinden ayıran özellikler lokasyon, sosyal, kültürel ve sporla ilgili detaylara ilişkin imkanlarla sınırlı değil; ev yaşam alanlarında da fonksiyonel özelliklerle konforunuz yükseltiliyor. Örneğin, 1+1 konutlarda yaşayanların en çok talep ettiği konuların başında gelen, fazla eşya, koli, ütü masası, elektrik süpürgesi gibi sürekli kullanılan malzemelerin ortalık yerde kalmasının önüne geçmek için her daireye yeterli ölçülerde “kiler“ eklendi.</p>
                            </div>
                        </li>
                        <li>
                            <a className="uk-accordion-title" href="#">Açık mutfak ve 3’lü ankastre set</a>
                            <div className="uk-accordion-content">
                                <p>Ulus Residence’da açık mutfak kavramı artık faklı bir tanıma sahip; mutfaklar salonun bir parçası, salona ait bir unsur veya bir köşe şeklini alıyor. Modern yaşamın tüm unsurlarını içinde barındıran dizaynıyla açık mutfağınız size üstün hareket kabiliyeti ve rahatlık sunarken zamandan tasarruf etmenizi sağlıyor.</p>
                            </div>
                        </li>
                        <li>
                            <a className="uk-accordion-title" href="#">Seramik Banyo</a>
                            <div className="uk-accordion-content">
                                <p>2+1’in banyo ferahlığında, bej tonları ve beyazın gözleri yormayan uyumunu görebilirsiniz... Doğadan alınan ilhamla tasarlanan banyolarda en son trendler sizler için tasarlandı.</p>
                            </div>
                        </li>
                        <li>
                            <a className="uk-accordion-title" href="#">Balkon</a>
                            <div className="uk-accordion-content">
                                <p>Her alanını keyife dönüştürdüğümüz Çorlu Ulus Residence’da geniş bir teras sevdiklerinizle hoşça vakit geçireceğiniz bir mekana dönüşüyor. İster sabah kahvaltınızı yapın isterseniz dostlarınızla koyu sohbetlere dalacağınız bir ortamlar organize edin... Bunun için detayları düşündük ve uyguladık... </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectDetailAccordion);