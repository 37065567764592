/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
const ContactIntro = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container">
                <div className="uk-flex uk-flex-center">
                    <h1 className="uk-text-bold uk-margin-remove">İletişim</h1>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ContactIntro);