/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import RoomDoubleImage from "../components/Rooms/RoomDoubleImage";
import RoomIntro from "../components/Rooms/RoomIntro";
import RoomIntroTwo from "../components/Rooms/RoomIntroTwo";
import RoomLayout from "../components/Rooms/RoomLayout";
import RoomLayoutTwo from "../components/Rooms/RoomLayoutTwo";

const RoomScreen = ({ t }) => {
    return (
        <>
            <RoomIntro/>
            <RoomLayout/>
            <RoomIntroTwo/>
            <RoomLayoutTwo/>
            <RoomDoubleImage/>
        </>
    );
};

export default withTranslation()(RoomScreen);