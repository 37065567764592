/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'

export default function ContactMap() {
    const iframeSrc = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.084754017571!2d27.7779023156162!3d41.15450191836229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b4e6590b912cbb%3A0xa389bccd1f742974!2sHatip%20Mh%2C%20Ali%20Osman%20%C3%87elebi%20Blv.%20No%3A36%2C%2059860%20%C3%87orlu%2FTekirda%C4%9F!5e0!3m2!1str!2str!4v1656367463733!5m2!1str!2str'

    return (
        <section>
            <div>
                <div className="uk-margin-auto uk-grid-collapse uk-child-width-1-1" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center">
                        <div className="uk-width-1-1 uk-height-large">
                            <iframe
                            src={iframeSrc}
                            frameborder="0"
                            style={{ border: 0 }}
                            className="uk-width-1-1 uk-height-1-1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
