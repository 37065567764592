import React from 'react'
import './Sidebar.css'
import sidebarItems from './SidebarItems.js';
import { Link } from 'react-router-dom';
import logo from "../../assets/logo.png";

const Sidebar = ({ isopen, toggle }) => {

    let opacityClasses = ['sidebar-container'];
    if (isopen) {
        opacityClasses.push('opacity-on')
    } else {
        opacityClasses.push('opacity-off')
    }


    return (
        <div className={opacityClasses.join(' ')} isopen={isopen.toString()} onClick={toggle}>
            <div className="icon">
                <div uk-icon="icon:close;" onClick={toggle} />
            </div>
            <div className="sidebar-wrapper">
                <div className="sidebar-menu">
                    {sidebarItems.map((item, index) => (
                        <Link to={item.link} key={index} className="sidebar-links">
                            {item.title}
                        </Link>
                    ))}
                </div>
                <div className='uk-flex uk-flex-center' style={{marginTop:"140px"}}>
                    <Link to="/"><img src={logo} alt="logo" data-uk-img className="logo"></img></Link>
                </div>
            </div>
        </div>

    )
}

export default Sidebar
