const translations = {
  en: {
    translation: {
      test: "Test",
    },
  },
  tr: {
    translation: {
      test: "Deneme",
      homeFirstContainer:"Yüksek yatırım değeriyle prestijli bir yaşam sizi bekliyor",
      homeFirstContainer2:"Anın tadını çıkararak yaşamın keyfini sürmek isteyenler için özenle tasarlandı...",
      homeFirstContainer3:"Toplam 17 bin 330 m2 arsa büyüklüğünde bir alana sahip olan Çorlu Ulus Residence projesi, 25 metre genişliğindeki caddeye cepheli şekilde inşa ediliyor. Dört bir yanı sokak ve yollarla çevrili olan Çorlu Ulus Residence’ın girişi de yine 25 metre genişliğindeki caddeden olacak şekilde projelendirildi. 17 bin 330 m2’lik arsa alanının sadece 3 bin 300 m2’si bina oturum alanlarına ayrılmış olan proje, 4 blok konut ve 1 blok ticari ünite şeklinde tasarlandı. Tamamında kiler bulunacak şekilde dizayn edilen konutların 40 adeti açık mutfak konseptinde, 2+1 daire şeklinde. 296 adeti ise yine açık mutfak konseptiyle 1+1 daire niteliği taşıyor. Ticari alan olarak da 300 m2 dükkanın yer aldığı projenin geri kalan 14 bin m2’lik alanının tamamı çocuk oyun alanı, çocuk havuzu, standartların üzerinde açık yüzme havuzu, açık otoparklar, yürüyüş ve peyzaj alanları gibi yaşama keyif katan detaylara ayrılarak tasarlandı..",
      homeSecondContainer:"Türkiye’nin en çok göç alan yerleşim yerleri arasında ilk sıralarda gelen Çorlu, nüfus olarak ülkemizdeki 52 vilayetten büyük olması ile dikkat çekiyor. İrili ufaklı 2 binden fazla sanayi tesisi ve işletmeye ev sahipliği yapan Çorlu’da ayrıca artan kamu yatırımları ve beraberinde gelen yeni atamalar da hem kiralık hem de satılık konuta olan ihtiyacı artırıyor. Başta İstanbul olmak üzere birçok ilden fabrika yatırımlarını çekmeye devam eden Çorlu ve çevresinde, çalışmakta olan nüfus da her geçen gün artıyor. Son 10 yılda nüfusu 1 milyona doğru hızla ilerleyen Çorlu’da, yaşanan talep patlamasının etkisiyle satılık ya da kiralık 1+1 konut bulmak neredeyse imkansız.",
      homeSecondContainer2:"Yüksek prim getirisiyle kısa sürede amortisman imkanı",
      homeSecondContainer3:"Hem mevcut hem de planlanan yatırımlar açısından potansiyeli yüksek bir bölgenin cazibe merkezlerinin başında gelen Çorlu’da, sanayileşme; İstanbul’a yakınlık; Çorlu Havalimanı’nın faaliyette olması; gelir seviyesinin yüksek olması; hızlı göç ve nüfusun hızla artması; en önemlisi de işsizliğin neredeyse olmaması, küçük konut yatırımcılarına gerek yüksek kira getirisi gerekse hızlı pirim gibi avantajlarla yatırımlarını kısa sürede amorti etme imkanı veriyor. Ayrıca, yatırım yapılan 1+1 konutların boş kalmaması da, yatırımcılar açısından bir başka artı değer olarak dikkat çekiyor.",
      homeThirdContainer:"Lüks yaşam hiç bu kadar ulaşılabilir olmamıştı...",
      homeThirdContainer2:"Çorlu Ulus Residence’da olmak, hem ev sahiplerine hem de kiracılara lüks bir konfor imkanı sağlıyor. Projenin yer aldığı lokasyonda artan konut ihtiyacına karşın kısıtlı olan konut arzından dolayı dairelere olan talep her geçen gün artarken yatırımcılar da kısa sürede yüksek kira getirisi, yüksek pirim ve amortisman imkanına sahip oluyor. Siz de ulaşılabilir lükse çocuklarınızın kumbara hesabındaki birikimleriyle sahip olabilir, karlı bir yatırım yaparak yarınlarını güvence altına alabilirsiniz...",
    },
  },
};

export default translations;