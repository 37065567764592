/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import image1 from "../../assets/Home/image-3.jpg"

const HomeThirdContainerMobile = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-hidden@m">
                <div className="uk-container uk-container-xlarge">
                    <div className="uk-flex-middle uk-grid-collapse" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div className="uk-width-2-5@m uk-flex uk-flex-middle uk-flex-center">
                            <div className="uk-width-5-6@m">
                                <img src={image1} alt="" data-uk-img className="uk-width-1-1 uk-border-rounded"></img>
                            </div>
                        </div>
                        <div className="uk-width-3-5@m uk-padding">
                            <div className="uk-h3 uk-margin-remove uk-text-bold uk-text-emphasis">{t("homeThirdContainer")}</div>
                            <p>{t("homeThirdContainer2")}</p>
                            <Link to="/gallery" className="uk-button uk-button-primary">Galeri</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(HomeThirdContainerMobile);