/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import slider1 from "../../assets/Home/Slideshow/1.jpg"
import slider2 from "../../assets/Home/Slideshow/2.jpg"
import slider3 from "../../assets/Home/Slideshow/3.jpg"
import slider4 from "../../assets/Home/Slideshow/4.jpg"
import slider5 from "../../assets/Home/Slideshow/5.jpg"

const HomeSlideshow = ({ t }) => {
    return (
        <div data-uk-slideshow="animation: push; autoplay:true; autoplay-interval:8000;">
            <div class="uk-position-relative uk-visible-toggle" tabindex="-1">

                <ul class="uk-slideshow-items" uk-height-viewport="offset-top:true;">
                    <li>
                        <div class="uk-inline" data-uk-cover>
                            <img src={slider1} alt="" data-uk-cover data-uk-img />
                            <div class="uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-middle">
                                <div className="uk-width-2-3@m">
                                    <div className="uk-padding ff-playfair fs-72 uk-text-muted" data-uk-scrollspy="cls:uk-animation-fade; delay:300;repeat:true;">
                                        <div>Konforun yeni tanımı:</div>
                                        <div className="uk-text-bold uk-text-emphasis">bir+bir</div>
                                        <Link to="/contact" className="uk-button uk-button-primary uk-button-large uk-text-bold">Bilgi Al</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="uk-inline" data-uk-cover>
                            <img src={slider2} alt="" data-uk-cover data-uk-img />
                            <div class="uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-middle">
                                <div className="uk-width-2-3@m">
                                    <div className="uk-padding ff-playfair fs-72 uk-text-muted" data-uk-scrollspy="cls:uk-animation-fade; delay:300;repeat:true;">
                                        <div>Prestiji ve lüksü <span className="uk-text-bold uk-text-emphasis">'doğa'</span>sıya yaşamak</div>
                                        <Link to="/contact" className="uk-button uk-button-primary uk-button-large uk-text-bold">Bilgi Al</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="uk-inline" data-uk-cover>
                            <img src={slider3} alt="" data-uk-cover data-uk-img />
                            <div class="uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-middle">
                                <div className="uk-width-2-3@m">
                                    <div className="uk-padding ff-playfair fs-72 uk-text-muted" data-uk-scrollspy="cls:uk-animation-fade; delay:300;repeat:true;">
                                        <div>Modern ve şık mimari</div>
                                        <div className="uk-text-bold uk-text-emphasis">tasarım</div>
                                        <Link to="/contact" className="uk-button uk-button-primary uk-button-large uk-text-bold">Bilgi Al</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="uk-inline" data-uk-cover>
                            <img src={slider4} alt="" data-uk-cover data-uk-img />
                            <div class="uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-middle">
                                <div className="uk-width-2-3@m">
                                    <div className="uk-padding ff-playfair fs-72 uk-text-muted" data-uk-scrollspy="cls:uk-animation-fade; delay:300;repeat:true;">
                                        <div><span className="uk-text-bold uk-text-emphasis">Minimalizm, fonksiyonellik </span></div><span>ve </span>
                                        <span className="uk-text-bold uk-text-emphasis">konfor </span>bir arada
                                        <div><Link to="/contact" className="uk-button uk-button-primary uk-button-large uk-text-bold">Bilgi Al</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="uk-inline" data-uk-cover>
                            <img src={slider5} alt="" data-uk-cover data-uk-img />
                            <div class="uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-middle">
                                <div className="uk-width-2-3@m">
                                    <div className="uk-padding ff-playfair fs-72 uk-text-muted" data-uk-scrollspy="cls:uk-animation-fade; delay:300;repeat:true;">
                                        <div>Küçük metrekarelere sığan</div>
                                        <div className="uk-text-bold uk-text-emphasis">kocaman yaşamlar</div>
                                        <Link to="/contact" className="uk-button uk-button-primary uk-button-large uk-text-bold">Bilgi Al</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slideshow-item="next"></a>
            </div>
            <ul class="uk-slideshow-nav uk-dotnav line-nav uk-position-bottom-center uk-margin"></ul>
        </div>
    );
};

export default withTranslation()(HomeSlideshow);