/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import assemcorp from "../assets/assemcorp-logo.png";
import { React} from "react";

const Navbar = ({ t , toggle }) => {
    return (
        <header className="navbar-bg" data-uk-sticky>
            <nav className="uk-container uk-container-xlarge uk-navbar-sticky uk-position-relative" data-uk-navbar data-uk-scrollspy="cls: uk-animation-fade; delay:300;">
                <div className="uk-navbar-left">
                    <div className="uk-navbar-item"><Link to="/"><img src={logo} alt="logo" data-uk-img className="logo"></img></Link></div>
                    <div className="uk-navbar-item link uk-flex uk-flex-column uk-flex-middle uk-visible@l">
                        <a href="#" className="uk-link-reset">+90 850 611 0 882</a>
                        <a href="#" className="uk-link-reset">construction@assemcorp.com</a>
                    </div>
                </div>
                <div className="uk-navbar-center uk-visible@m">
                    <div className="uk-navbar-item link">
                        <Link to="/" className="uk-link-reset">Anasayfa</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/project-detail" className="uk-link-reset">Proje Özellikleri</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/room" className="uk-link-reset">Kat ve Daire Planları</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/gallery" className="uk-link-reset">Galeri</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/contact" className="uk-link-reset">İletişim</Link>
                    </div>
                </div>
                <div className="uk-navbar-right uk-visible@m">
                    <div className="uk-navbar-item"><a href="https://www.assemcorp.com/tr/" target="_blank"><img src={assemcorp} alt="logo" data-uk-img className="assemcorp-logo"></img></a></div>
                    <div className="uk-navbar-item link">
                        <a href="#" className="uk-link-reset">TR</a>
                    </div>
                </div>
                <div className="uk-navbar-right uk-hidden@m">
                    <div className="uk-navbar-item link">
                        <a className="uk-link-reset uk-text-large" data-uk-icon="menu" onClick={toggle}></a>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default withTranslation()(Navbar);