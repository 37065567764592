/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import image1 from "../../assets/Room/room-plan-1.png";
import image2 from "../../assets/Room/room-plan-2.png";

const RoomDoubleImage = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-padding-remove-top">
                <div className="uk-container uk-container-large">
                    <div className="uk-child-width-1-2@m" data-uk-grid>
                        <div>
                            <div className="uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                                <a className="uk-inline-clip uk-transition-toggle uk-width-1-1 uk-height-1-1" tabindex="0" href={image1}>
                                    <img src={image1} className="uk-width-1-1 uk-height-1-1" alt="" data-uk-img />
                                    <div className="uk-transition-fade uk-position-cover uk-overlay uk-overlay-default uk-flex uk-flex-center uk-flex-middle">
                                        <div className="uk-text-emphasis" data-uk-icon="icon:plus; ratio:3;"/>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="uk-width-1-1 uk-height-1-1" data-uk-lightbox>
                                <a className="uk-inline-clip uk-transition-toggle uk-width-1-1 uk-height-1-1" tabindex="0" href={image1}>
                                    <img src={image2} className="uk-width-1-1 uk-height-1-1" alt="" data-uk-img />
                                    <div className="uk-transition-fade uk-position-cover uk-overlay uk-overlay-default uk-flex uk-flex-center uk-flex-middle">
                                        <div className="uk-text-emphasis" data-uk-icon="icon:plus; ratio:3;"/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(RoomDoubleImage);