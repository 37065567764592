/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import image1 from "../../assets/Home/image-2.jpg"

const HomeSecondContainerMobile = ({ t }) => {
    return (
        <section className="uk-section uk-background-primary uk-visible@m">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-flex-middle" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="uk-width-3-5@m">
                        <p>{t("homeSecondContainer")}</p>
                        <div className="uk-h5 uk-margin-small-top uk-text-emphasis uk-text-bold">{t("homeSecondContainer2")}</div>
                        <p>{t("homeSecondContainer3")}</p>
                        <Link to="/room" className="uk-button uk-button-primary">Daire Planları</Link>
                    </div>
                    <div className="uk-width-2-5@m uk-flex uk-flex-middle uk-flex-center">
                        <div className="uk-width-5-6@m">
                            <img src={image1} alt="" data-uk-img className="uk-width-1-1 uk-border-rounded"></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(HomeSecondContainerMobile);