/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const GalleryIntro = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-bottom" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
            <div className="uk-container">
                <div className="uk-flex uk-flex-center">
                    <h1 className="fs-42 uk-text-emphasis uk-text-bold">Galeri</h1>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(GalleryIntro);