/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import GalleryContent from "../components/Gallery/GalleryContent";
import GalleryIntro from "../components/Gallery/GalleryIntro";
import Video from "../components/Common/Video";
const GalleryScreen = ({ t }) => {
    return (
        <>
            <GalleryIntro/>
            <Video/>
            <GalleryContent/>
        </>
    );
};

export default withTranslation()(GalleryScreen);