/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import ContactDescripton from "../components/Contact/ContactDescripton";
import ContactForm from "../components/Contact/ContactForm";
import ContactIntro from "../components/Contact/ContactIntro";
import ContactMap from "../components/Contact/ContactMap";
const ContactScreen = ({ t }) => {
    return (
        <>
            <ContactIntro/>
            <ContactForm/>
            <ContactDescripton/>
            <ContactMap/>
        </>
    );
};

export default withTranslation()(ContactScreen);