const navbarItems = [
    {
        title: 'Anasayfa',
        link: '/',
    },
    {
        title: 'Proje Özellikleri',
        link: '/project-detail',
    },
    {
        title: 'Kat ve Daire Planları',
        link: '/room',
    },
    {
        title: 'Galeri',
        link: '/gallery',
    },
    {
        title: 'İletişim',
        link: '/contact',
    },
]

export default navbarItems