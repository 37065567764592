/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const ProjectDetailSiteFeatures = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-padding-remove-bottom">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div>
                            <h2 className="uk-text-bold">Site Özellikleri</h2>
                            <p>Çorlu Ulus Residence’da, sosyal tesisler standart ölçülerin dışında yüzme havuzu; çocuk havuzu; çocuk oyun alanı; yürüyüş yolları; açık otopark alanları; fitness odası; bay/bayan sauna ve hamamları; bilardo, masa tenisi, langırt v.b. etkinlikleri içeren oyun ve dinlenme odaları; hayatı ve yaşamı kolaylaştırıcı alanlar gibi birçok detay düşünülerek tasarlandı</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-section uk-padding-remove-bottom">
                <div className="uk-container">
                    <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center" data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div>
                            <h2 className="uk-text-bold">Profesyonel güvenlik</h2>
                            <p>24 saat izleme ve kayıt yapabilen gece görüşlü güvenlik kameraları, plaka tanıma sistemli araç giriş ve çıkış bariyerleri, 24 saat sabit ve gezici güvenlik ekipleriyle birlikte profesyonel bir yönetim hizmeti sunulan Ulus Residence’da güvenliğiniz emin ellerde.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-section">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@m uk-child-width-1-4@l uk-grid-match uk-text-center" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Güvenlik</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Sosyal Tesisler</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Yüzme Havuzu</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Çocuk Havuzu</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Çocuk Oyun Alanı</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Yürüyüş Yolları</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Açık Otopark Alanları</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Fitness Odası</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Bay/Bayan Sauna ve Hamam</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Bilardo</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Masa Tenisi</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover">
                                <div className="uk-card-body uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                                    <span className="uk-margin-small-right" data-uk-icon="check"></span><span>Langırt</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(ProjectDetailSiteFeatures);