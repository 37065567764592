/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import image1 from "../../assets/Home/image-1.jpg"

const HomeFirstContainer = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-top">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-flex-middle" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="uk-width-3-5@m uk-flex-last uk-flex-first@m uk-padding uk-padding-large">
                        <h1 className="uk-h3 uk-margin-remove uk-text-bold uk-text-emphasis">{t("homeFirstContainer")}</h1>
                        <h2 className="uk-h5 uk-margin-small-top">{t("homeFirstContainer2")}</h2>
                        <p>{t("homeFirstContainer3")}</p>
                        <Link to="/project-detail" className="uk-button uk-button-primary">Proje Hakkında</Link>
                    </div>
                    <div className="uk-width-2-5@m uk-flex-first uk-flex-last@m uk-flex uk-flex-middle uk-flex-center">
                        <div className="uk-width-5-6@m">
                            <img src={image1} alt="" data-uk-img className="uk-width-1-1 uk-border-rounded"></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(HomeFirstContainer);